<template>
  <v-container>
    <v-card elevation="2" link to="/blocks">
      <v-card-title>
        <v-icon>mdi-arrow-left</v-icon>
        <span>区块详情</span>
      </v-card-title>
    </v-card>
    <v-card elevation="2" class="card">
      <div class="box">
        <v-row v-for="(_, key) in block" :key="key">
          <v-col cols="4">{{ _[0] }}</v-col>
          <v-col cols="8">
            <RippleLink v-if="key === 'validator'" :to="`/address/${_[1]}`">
              {{ _[1] }}
            </RippleLink>
            <RippleLink
              v-else-if="key === 'transaction_count'"
              :to="`/transactions/${_[1]}`"
            >
              {{ _[1] }}
            </RippleLink>
            <span v-else-if="key === 'time'">
              {{ YMDHms(_[1]) }}
            </span>
            <span v-else>{{ _[1] }}</span>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { RippleLink } from '@/components';
import { Block } from '@/api';
import { YMDHms } from '@/utils';

export default {
  name: 'Block',
  components: { RippleLink },
  data: () => ({
    block: {
      number: ['区块高度', '-'],
      time: ['出块时间', '-'],
      validator: ['出块者', '-'],
      difficulty: ['出块难度', '-'],
      size: ['区块容量', '-'],
      extra: ['扩展信息', '-'],
      hash: ['区块哈希', '-'],
      parent_hash: ['父区块哈希', '-'],
      transaction_count: ['交易数', '-'],
    },
  }),
  mounted() {
    this.getBlock();
  },
  methods: {
    YMDHms,
    getBlock() {
      Block.getBlock(this.$route.params.number).then((res) => {
        const tmp = { ...this.block };
        Object.keys(tmp).forEach((key) => (tmp[key][1] = res.block[key]));
        this.block = tmp;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  margin-top: 16px;
  .box {
    padding: 16px;
  }
}
</style>
